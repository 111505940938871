<template lang="pug">
  fade-transition
    button#to-top(aria-label="To Top", class="go-top", ref="goTop", v-show="isVisible", @click="scrollUp")
      i.fa.fa-angle-up
</template>

<script>
import { FadeTransition } from 'vue2-transitions'
import { normRoute, query } from '~~/utils/utils'
// import get from 'lodash/get'

export default {
  name: 'BackToTopComponent',

  components: {
    FadeTransition
  },

  inject: ['pageType'],

  data: () => ({
    isVisible: false
    // pageType: null
  }),

  computed: {
    type() {
      return this.$store.getters.typeList
    },

    loadedPages() {
      return this.$store.getters[`${this.type}/loadedPages`]
    }
  },

  mounted() {
    document.addEventListener('scroll', this.onScrollHandler, false)
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScrollHandler, false)
  },

  methods: {
    onScrollHandler() {
      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop
      const backToTopButton = this.$refs.goTop
      if (scrollPosition > 250) {
        backToTopButton.classList.add('isVisible')
        this.isVisible = true
      } else {
        backToTopButton.classList.remove('isVisible')
        this.isVisible = false
      }
    },

    scrollUp() {
      const el = document.getElementById('__nuxt')
      const options = {
        block: 'start',
        inline: 'nearest'
      }
      if (el) el.scrollIntoView(options)
    },

    updateRoute() {
      const route = normRoute(this.$route)
      if (route.query.page) delete route.query.page
      this.scrollUp()
      this.$router.push(this.localePath(route))
    },

    async backToTop() {
      if (this.pageType !== 'listing') {
        this.scrollUp()
        return
      }

      if (this.loadedPages.includes(1)) {
        this.updateRoute()
        return
      }

      this.$root.$emit('loading-start')
      const pageNumber = 1
      await this.$store.dispatch(`${this.type}/getPage`, {
        prefilterQuery: query(this.$store, this.$route),
        pageNumber,
        force: true
      })
      this.$root.$emit('loading-stop')
      this.updateRoute()
    }
  }
}
</script>

<style lang="sass" scoped>
.go-top
  border-radius: 5px
  background-color: rgba(1, 14, 27, 0.7)
  position: fixed
  width: 45px
  height: 45px
  display: block
  right: 24px
  bottom: 105px
  border: none
  z-index: 999
  user-select: none
  cursor: pointer
  &:hover
    background-color: rgba(1, 14, 27, 0.9)
  .fa
    color: white
    font-size: 22px
</style>
